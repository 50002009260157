import { Pipe, PipeTransform } from '@angular/core';

/**
 * Creates an n sized array
 *
 * Returns an Array of 'value' size
 */
@Pipe({name: 'wsFill'})
export class FillPipe implements PipeTransform {

  public transform(value: number): number[] {
    return (new Array(value)).fill(1);
  }
}
